.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.content > p {
  width: 100%;
  white-space: pre-wrap;
  text-align: center;
}

.pageInfo {
  width: 52%;
  text-align: left;
  white-space: pre-wrap;
  /* margin-top: 20px; */
}

.pageInfo > h2 {
  margin-bottom: 10px;
  text-align: center;
}

.pageInfo > ol > li {
  margin-left: 50px;
}

@media screen and (max-width: 1440px) {
  .pageInfo {
    width: 55%;
  }
}

@media screen and (max-width: 1280px) {
  .pageInfo {
    width: 62%;
  }
}

@media screen and (max-width: 1024px) {
  .pageInfo {
    width: 71%;
  }
}

@media screen and (max-width: 835px) {
  .pageInfo {
    width: 90%;
  }
}
