/* .container > img {
  width: 100%;
  filter: brightness(40%);
}

.container > img:first-child {
  display: block;
}

.container > img:nth-child(2) {
  display: none;
} */

.container {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../assets/malav-box.jpg) center;
  background-size: cover;
}

.container > h1 {
  color: #fff;
  margin-top: 0;
  /* margin-bottom: 50px; */
}

/* .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.columns {
  display: flex;
  /* align-items: stretch; */
  color: #fff;
  /* font-size: 1.2rem; */
  width: 90%;
  gap: 50px;
  /* height: 100%; */
  /* grid-template-areas:
  "h1 h2"
  "t1 t2"
  "b1 b2";
  grid-template-columns: repeat(autofill, minmax(400px, 1fr)); */
  /* justify-items: center; */
  /* grid-gap: 1rem; */
}

.columns > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  width: 50%;
  /* height: 100%; */
  padding: 50px 30px;
  gap: 20px;
  line-height: 24px;
  background-color: rgba(23, 179, 173, 0.1);
  border: 5px solid rgba(23, 179, 173, 0.5);
}

/* h3.first {
  grid-area: h1;
}

p.first {
  grid-area: t1;
}

a.first {
  grid-area: b1;
}

h3.second {
  grid-area: h2;
}

p.second {
  grid-area: t2;
}

a.second {
  grid-area: b2;
} */

@media screen and (max-width: 600px) {
  .container {
    height: 100%;
    padding: 50px 0;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../assets/vertical-box.jpg) center;
    background-size: cover;
  }

  .columns {
    flex-direction: column;
    align-items: center;
    /* width: 100%; */
    gap: 30px;
  }

  .columns > div {
    width: 100%;
    padding: 30px;
  }
}

@media screen and (min-width: 601px) and (max-width: 992px) {
  .container {
    height: 100%;
    padding: 50px 0;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../assets/vertical-box.jpg) center;
    background-size: cover;
  }

  .columns {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .columns > div {
    width: 60%;
  }
}

/* @media screen and (max-width: 930px) {
  .columns {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 720px) {
  .columns {
    display: block;
  }

  .container > img:first-child {
    display: none;
  }

  .container > img:nth-child(2) {
    display: block;
  }

  .columns {
    font-size: 1.4rem;
  }

  .columns a + h3 {
    margin-top: 1rem;
  }

  .columns a {
    margin-top: 10px;
  }
}

@media screen and (max-width: 500px) {
  .columns {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 400px) {
  .columns {
    font-size: 1.0rem;
  }
}

@media screen and (max-width: 370px) {
  .columns {
    font-size: 0.9rem;
  }

  .columns a + h3 {
    margin-top: 8px;
  }

  .columns a {
    margin-top: 5px;
  }
} */
