.inp {
  width: 100%;
  font-size: 1.375rem;
  margin-top: 0.9375rem;
  outline: none;
  border: none;
  border-bottom: 1px solid black;
}

.inp:focus {
  border-bottom: 1px solid var(--primary-color);
}
