[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none
}

[type="radio"]:not(:checked)+span,
[type="radio"]:checked+span {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  -webkit-transition: .28s ease;
  transition: .28s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

[type="radio"]+span:before,
[type="radio"]+span:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  -webkit-transition: .28s ease;
  transition: .28s ease
}

[type="radio"]:not(:checked)+span:before,
[type="radio"]:not(:checked)+span:after,
[type="radio"]:checked+span:before,
[type="radio"]:checked+span:after {
  border-radius: 50%
}

[type="radio"]:not(:checked)+span:before,
[type="radio"]:not(:checked)+span:after {
  border: 2px solid #5a5a5a
}

[type="radio"]:not(:checked)+span:after {
  -webkit-transform: scale(0);
  transform: scale(0)
}

[type="radio"]:checked+span:before {
  border: 2px solid transparent
}

[type="radio"]:checked+span:after {
  border: 2px solid var(--primary-color)
}

[type="radio"]:checked+span:after {
  background-color: var(--primary-color)
}

[type="radio"]:checked+span:after {
  -webkit-transform: scale(1.02);
  transform: scale(1.02)
}

[type="radio"]:disabled:not(:checked)+span:before,
[type="radio"]:disabled:checked+span:before {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.42)
}

[type="radio"]:disabled+span {
  color: rgba(0, 0, 0, 0.42)
}

[type="radio"]:disabled:not(:checked)+span:before {
  border-color: rgba(0, 0, 0, 0.42)
}

[type="radio"]:disabled:checked+span:after {
  background-color: rgba(0, 0, 0, 0.42);
  border-color: #949494
}
