.addContainer, .resourcesTable {
  margin: 5px auto;
  width: 60%;
}

.hint {
  width: fit-content;
  margin: 15px auto;
}

.resourcesTable {
  font-size: 1.0rem;
  border-collapse: collapse;
}

.resourcesTable td, th {
  border: 2px solid var(--primary-color);
  text-align: center;
}

.resourcesTable th {
  color: #000000;
  font-weight: bold;
}

.addContainer {
  display: flex;
  gap: 5px;
  align-items: center;
}

.resourcesButton {
  width: fit-content;
  font-size: 1.0rem;
  height: 2.8rem;
  line-height: 2.8rem;
}

.addButton {
  font-size: 1.0rem;
  width: 25rem;
  height: 2.8rem;
  line-height: 2.8rem;
}

.tableRow:hover {
  cursor: pointer;
  background-color: var(--primary-color);
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .addContainer, .resourcesTable {
    width: 80%;
  }
}

@media screen and (max-width: 515px) {
  .addContainer, .resourcesTable {
    width: 90%;
  }
}

.resourceContainer {
  width: 60%;
  margin: 5px auto;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-content: center; */
}

.resourceContainer .buttonsContainer {
  display: flex;
  gap: 5px;
  width: fit-content;
  margin: 25px auto;
}

.resourceId {
  margin: 20px auto;
  margin-bottom: 0;
  width: fit-content;
  font-size: 1.5rem;
}

.resourceIdValue {
  margin: 5px auto;
  width: fit-content;
}

.resourceContainer button {
  font-size: 1.3rem;
  /* padding: 15px; */
  height: 2.2rem;
  line-height: 2.2rem;
}

.resourceContainer textarea {
  resize: vertical;
  padding: 0.5rem;
  margin-top: 1rem;
  width: 90%;
  /* height: 7rem; */
  min-height: 4rem;
  border: none;
  outline: 1px solid #000;
  transition: outline 300ms;
  font-size: 1.2rem;
  background-color: transparent;
}

.resourceContainer textarea:focus {
  border: none;
  outline: 1px solid var(--primary-color);
}

.resourceContainer textarea::placeholder {
  color: #9e9e9e;
  opacity: 1.0;
}

.uploadBlock {
  margin-top: 5px;
}

.uploadSuccess {
  margin-top: 5px;
  color: var(--primary-color);
}

.uploadFailed {
  margin-top: 5px;
  color: #F00;
}

.imageResource {
  display: block;
  margin-top: 15px;
}
