
.chooseBoxTypeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseBoxTypeContainer > p {
  font-size: 1.5rem;
}

.boxContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.boxContainer > img {
  width: 40%;
  border: 5px solid transparent;
}

.boxContainer > .selectedBox {
  border: 5px solid rgba(23, 179, 173, 0.5);
  transition: border .1s ease-out;
}

/* @media screen and (max-width: 1024px) {
  .boxContainer > img {
    width: 40%;
  }
} */

/* @media screen and (max-width: 600px) {
  .boxContainer > img {
    width: 50%;
  }
} */
