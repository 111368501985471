.container {
  width: 100%;
  padding: 0.1px 0 50px;
  text-align: justify;
  /* background-color: rgba(255, 255, 255, 0.5); */
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../assets/malav-detailed.jpg) center;
  background-size: cover;
}

.content {
  width: 90%;
  margin: 0 auto;
  /* max-width: 60%; */
  /* margin: 1.5rem auto; */
  padding: 50px;
  text-align: center;
  color: #fff;
  background-color: rgba(23, 179, 173, 0.1);
  border: 5px solid rgba(23, 179, 173, 0.5);
}

pre.pageInfo {
  white-space: pre-wrap;
}

ul li::marker {
  font-size: 1.4rem;
  color: var(--primary-color);
}

h2 {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

div > div > p {
  white-space: pre-wrap;
}

/* @media screen and (max-width: 600px) {
  .container {
    margin: 0 auto;
    width: 100%;
    padding: 30px;
  }
} */

@media screen and (max-width: 600px) {
  .container {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../assets/malav-detailed-mobile.jpg) center;
    background-size: cover;
    padding: 0.1px 0 20px;
  }

  .content {
    padding: 30px;
  }
}
