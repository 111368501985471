.navmenu {
  display: flex;
  /* position: fixed; */
  /* width: 100%; */
  list-style-type: none;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  background-color: var(--primary-color);
  font-size: 20px;
  min-height: 60px;
  /* z-index: 5; */
}

/* .navmenu li:first-child {
  margin-left: 2.5rem;
} */

.navmenu > li > ul > li a.activeLink {
  border-bottom: 3px solid #fff;
}

.navmenu > li > ul > li a.activeLink:hover {
  color: #fff;
}

.aboutLink > a,
.detailedLink > a {
  white-space: nowrap;
}

.logo {
  font-size: 25px;
}

.menu {
  /* margin-left: 10rem; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 80px;
  list-style-type: none;
}

/* .menu li + li {
  margin-left: 6rem;
} */

.navmenu a,
.navmenu li {
  font-weight: bold;
  text-decoration: none;
  color: #fff;
}

.navmenu a {
  transition: 0.3s;
}

.navmenu a:hover:not(.logo),
.navmenu > li > ul > li:hover {
  color: var(--link-hover-color);
  /* text-shadow: 1px 1px 2px blue; */
}

/* .navmenu a:hover {
} */

.burger {
  /* visibility: hidden; */
  display: none;
  cursor: pointer;
  margin-left: auto;
  margin-right: 5px;
}

.burger:hover .bar {
  background-color: var(--link-hover-color);
}

li.lang:hover {
  color: var(--link-hover-color);
}

.bar {
  width: 30px;
  height: 3px;
  border-radius: 5px;
  background-color: #fff;
}

.bar + .bar {
  margin-top: 3px;
}

li.lang {
  /* position: relative; */
  color: #fff;
  /* margin-left: 1rem; */
  /* margin-right: 2.5rem; */
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.3s;
  /* display: flex;
  align-items: center; */
  /* gap: 5px; */
}

.langIcon {
  font-size: 1.8rem;
}

.langButton {
  display: flex;
  align-items: center;
  gap: 5px;
}

.dropdownContent {
  position: absolute;
  text-align: center;
  top: 60px;
  right: 20px;
  list-style: none;
  background-color: var(--primary-color);
  display: block;
}

.dropdownContent > li {
  padding: 10px 25px;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  /* color: var(--primary-color); */
}

.dropdownContent > li:last-child {
  border-bottom: 1px solid #fff;
}

.lang .dropdownContent > li:hover {
  color: var(--primary-color);
  background-color: rgba(255, 255, 255, 1);
}

.hideDropdown {
  display: none;
}

/* @media screen and (max-width: 1200px) {
  .menu {
    margin-left: 60px;
  }
}

@media screen and (max-width: 960px) {
  .menu li + li {
    margin-left: 40px;
  }
} */

@media screen and (max-width: 1280px) {
  .menu {
    gap: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .menu {
    gap: 40px;
  }
}

@media screen and (max-width: 962px) {
  .menu {
    gap: 25px;
  }
}

@media screen and (max-width: 835px) {
  .menu {
    gap: 15px;
    /* margin-left: 55px; */
  }

  /* .menu li + li {
    margin-left: 30px;
  } */
}

@media screen and (max-width: 800px) {
  .menu {
    gap: 10px;
  }
}

@media screen and (max-width: 768px) {
  .burger {
    display: block;
    margin-right: 20px;
    /* visibility: visible; */
  }

  .navmenu {
    position: sticky;
    z-index: 999999;
  }

  .menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100vh;
    gap: 0;
    justify-content: flex-start;
  }

  .menu {
    margin-left: 0;
  }

  .menu li {
    width: 100%;
    height: 15vh;
    line-height: 15vh;
    font-size: 2.5rem;
    text-align: center;
  }

  .menu li a {
    display: block;
    width: 100%;
    border-top: 1px solid var(--background);
  }

  .menu li:first-child,
  .menu li + li {
    margin-left: 0;
  }

  .menu li:hover {
    color: var(--link-hover-color);
    background-color: var(--background);
  }

  .menu a:hover {
    color: var(--link-hover-color);
  }

  .enable {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--primary-color);
  }

  .dropdownContent > li {
    padding: 10px 23px;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    /* color: var(--primary-color); */
  }
}
