.container {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../assets/malav-detailed.jpg) center;
  background-size: cover;
  padding: 0.1px 0 50px;
}

.content {
  margin: 0 auto;
  width: 90%;
  padding: 50px;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #fff;
  background-color: rgba(23, 179, 173, 0.1);
  border: 5px solid rgba(23, 179, 173, 0.5);
}

.content > h3:not(:last-of-type) {
  margin-bottom: 5px;
}

.content > h2, .content > p {
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .container {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../assets/malav-detailed-mobile.jpg) center;
    background-size: cover;
    padding: 0.1px 0 20px;
  }

  .content {
    padding: 30px;
  }
}
