.content {
  /* padding: 3rem; */
  /* padding-top: 0; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 50px; */
  padding: 50px;
  margin-bottom: 30px;
  background-color: rgba(255, 255, 255, 0.5);
}

.content > img {
  width: 40%;
  border: 5px solid rgba(23, 179, 173, 1);
}

.content > .text {
  width: 55%;
  text-align: center;
  white-space: pre-line;
}

.content > button {
  min-width: fit-content;
  width: 40vw;
}

.content > p {
  font-size: 1.2rem;
}

.text > p + p {
  margin-top: 10px;
}

.next {
  margin: 30px auto;
}

.selectBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  margin: 0 auto;
}

.selectBox > p {
  font-size: 1.5rem;
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .content {
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin: 0 50px 30px;
    padding-bottom: 30px;
  }

  .content > img {
    width: 100%;
  }

  .content > .text {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .content {
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0 0 30px;
    gap: 0;
    /* background-color: none; */
  }

  .content > img {
    width: 100%;
    border-left: none;
    border-right: none;
  }

  .content > .text {
    width: 100%;
    padding: 30px 50px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .next {
    margin: 30px auto;
  }
}

@media screen and (max-width: 1280px) {
  .selectBox {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .selectBox {
    width: 55%;
  }
}

@media screen and (max-width: 835px) {
  .selectBox {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .selectBox {
    width: 65%;
  }
}

@media screen and (max-width: 601px) {
  .selectBox {
    width: 75%;
  }
}

@media screen and (max-width: 500px) {
  .selectBox {
    width: 90%;
  }
}

/* @media screen and (min-width: 1025px) {
  .content > .text {
    width: 40%;
  }
} */

/* @media screen and (min-width: 768px) {
  .content > img {
    width: 100%;
  }
} */
