/*
https://stackoverflow.com/questions/600743/how-to-get-div-height-to-auto-adjust-to-background-size
*/

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../assets/202.jpg) center;
  background-size: cover;
  /* position: relative; */
}

.mainText {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 10px;
  width: 80%;
  color: white;
  /* z-index: 2; */
  /* position: absolute; */
  /* left: 5%; */
  /* top: 35%; */
  /* bottom: 32px; */
}

.mainText > .companyName {
  color: var(--primary-color);
  font-size: 3rem;
}

.mainText > p {
  font-size: 1.2rem;
  white-space: pre-wrap;
}

/* .imgMain {
  display: block;
  width: 100%;
  height: calc(100vh - 60px);
  object-fit: cover;
  filter: brightness(40%);
  z-index: 1;
} */

/* .logo {
  width: fit-content;
  margin: 20px auto;
  font-size: 4rem;
  color: var(--primary-color);
} */

.buttons {
  display: flex;
  width: fit-content;
  margin-top: 20px;
  gap: 20px;
}

/* .detailsBtn {
  width: 15rem !important;
  min-width: fit-content !important;
  height: 2.5rem !important;
  line-height: 2.5rem !important;
} */

.surprise {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 30px 0;
  border: 10px solid var(--primary-color);
  border-left: none;
  border-right: none;
  background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  /* margin: 25px auto; */
  /* height: 7rem; */
  /* gap: 50px; */
  /* min-height: 9rem; */
}

.surprise > p {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 3px;
  color: var(--primary-color);
}

/* .surprise > p:first-child {
  margin-left: 0.5rem;
} */

/* .surprise > p:last-child {
  color: var(--primary-color);
  margin-left: auto;
  margin-right: 0.5rem;
} */

/* .img2 {
  display: block;
  width: 100%;
  filter: brightness(40%);
  z-index: 1;
} */

.secondContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../assets/197.jpg) center;
  background-size: cover;
  /* position: relative; */
  /* padding: 200px 0; */
}

.secondContainer > .innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 80%;
  font-size: 1.2rem;
  text-align: center;
  color: white;
  /* z-index: 2; */
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
}

.secondContainer > .innerContainer > p {
  white-space: pre-wrap;
}

@media screen and (max-width: 900px) {
  .surprise {
    display: flex;
    /* justify-content: center; */
    gap: 30px;
    padding: 30px;
  }

  .surprise > p {
    /* padding: 0 30px; */
    font-size: 1.3rem;
    text-align: center;
    /* background-color: aquamarine; */
  }

  .surprise > p:first-child {
    text-align: left;
  }

  .surprise > p:last-child {
    text-align: right;
  }

  .buttons {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .secondContainer .buttons > a {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    .surprise {
      padding: 20px 40px;
    }
  }

  @media screen and (max-width: 601px) {
    .surprise {
      padding: 10px 30px;
      border-width: 8px;
    }
  }

  /* @media screen and (max-width: 532px) {
    .surprise > p {
      font-size: 0.6rem;
    }
  } */

  /* .mainText {
    width: 80%;
  } */

  /* .secondContainer a {
    font-size: 1.2rem !important;
  } */

  /* .buttons a + a {   */
  /*   margin: 0;       */
  /*   margin-top: 5px; */
  /* }                  */
}

/* @media screen and (max-width: 600px) {
  .mainText {
    top: initial;
    bottom: 16px;
  }

  .secondContainer > p {
    font-size: 1.0rem;
  }

  .detailsBtn {
    width: 13rem !important;
  }

  .imgMain {
    height: calc(35vh);
  }
} */

/* @media screen and (max-width: 500px) {
  .surprise > p {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 450px) {
  .secondContainer img {
    height: 350px;
  }
} */

/* @media screen and (max-width: 400px) {
  .companyName {
    display: none;
  }

  .secondContainer a {
    font-size: 1.2rem !important;
  }

  .surprise > p {
    font-size: 1.4rem;
  }

} */
