.footer {
  margin-top: auto;
  flex: 0;
  display: flex;
  font-size: 0.9rem;
  color: #fff;
  background-color: var(--primary-color);
  padding: 3rem;
}

.column + .column {
  margin-left: 2.5rem;
}

.footer h1 {
  font-size: 1.0rem;
  margin-bottom: 10px;
}

.links {
  display: flex;
  flex-direction: column;
}

.link {
  display: block;
  text-decoration: none;
  color: #fff;
  transition: 0.3s;
}

.link:hover {
  color: var(--link-hover-color);
}

.columnContent {
  list-style-type: none;
}

.columnContent > li {
  min-height: 1.25rem;

}

.columnContent > li + li {
  margin-top: 0.3125rem;
}

.column img {
  display: block;
  max-height: 3.0rem;
  width: auto;
  height: auto;
}

/* .column img + img {   */
/*   margin-top: 0.5rem; */
/* }                     */

.payments {
  display: flex;
}

.paymentsRow > img {
  display: block;
  /* border: 1px solid red; */
}

.paymentsRow {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 15px;
  align-items: center;
  /* justify-content: center; */
}

/* h1.mobilePay {       */
/*   color: #5A78FF;    */
/*   font-size: 2.2rem; */
/* }                    */

.paymentsMobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
  }

  .column {
    padding-bottom: 7px;
  }

  .column + .column {
    margin-left: 0;
  }

  .column > h1 {
    position: relative;
    font-size: 14px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
  }

  .column > h1:after {
    content: '+';
    position: absolute;
    right: 5px;
  }

  .column > ul {
    display: none;
  }

  .column > ul.active {
    display: block;
    font-size: 12px;
  }
  .column > ul.active.paymentRowCont {
    display: flex;
    /* border: 1px solid red; */
    flex-direction: row !important;
    align-items: center;
    gap: 10px;
  }
  .paymentRowCont > li {
    width: fit-content;
    /* border: 1px solid green; */
  }

  .paymentsRow {
    display: none;
  }

  .paymentsMobile {
    display: flex;
    gap: 5px;
  }

  .paymentsMobile > img {
    /* border: 1px solid red; */
  }

  /* .column h1.active:after { */
  /*   content: ' +';          */
  /*   display: inline-block;  */
  /* }                         */
}
