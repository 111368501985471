.questionText {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.checkBoxesContainer {
  margin-left: 33%;
}

@media screen and (max-width: 1024px) {
  .checkBoxesContainer {
    margin-left: 32%;
  }
}

@media screen and (max-width: 835px) {
  .checkBoxesContainer {
    margin-left: 30%;
  }
}

@media screen and (max-width: 700px) {
  .checkBoxesContainer {
    margin-left: 28%;
  }
}

@media screen and (max-width: 599px) {
  .checkBoxesContainer {
    margin-left: 25%;
  }
}
