@import url('https://fonts.cdnfonts.com/css/montserrat');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

:root {
  --primary-color: #17b3ad;
  --secondary-color: #29a0cb;
  --background: #f0eee9;
  --link-hover-color: #2bede6;
}

*, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', 'Roboto', sans-serif;
}

.main-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  background-color: var(--background);

}

.content {
  flex: 1;
}

input[type="text"] {
  background-color: var(--background);
}

.question-text {
  font-size: 1.875rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

@media screen and (min-width: 1025px) {
  html {
    font-size: 18px;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  html {
    font-size: 12px;
  }
}
