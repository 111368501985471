/* .box {
  display: block;
  margin: 5px auto;
  width: 30%;
} */

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../assets/thank-you.jpg);
  background-size: cover;
  background-position: center;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 50px 0;
  background-color: rgba(23, 179, 173, 0.5);
  border: 5px solid rgba(255, 255, 255, 0.5);
}

.message > * {
  color: #fff;
}

.message > img {
  width: 15%;
}
