/* input from Materialize CSS */

input[type=text].validate {
  /* font-size: 100%; */
  line-height: 1.15;
  margin: 0;
  width: 100%;
  overflow: visible;
  background-color: transparent;
}

.input-field > input[type=text].validate {
  border-bottom: 1px solid black;
}

label {
  font-size: .8rem;
}

.input-field {
  margin: 0;
  height: 50px;
  width: 100%;
}

.input-field input {
  height: 100%;
  font-size: 1.2rem;
  width: 100%;
  padding-left: 2rem;
}

.input-field input:focus,
.input-field input[type=text]:valid,
.input-field input[type=email]:valid {
  border-bottom: 1px solid black;
  box-shadow: none;
}

.input-field label {
  top: 0;
  left: 0;
}

::placeholder {
  color: #d1d1d1;
}

input:not([type]),
input[type=text],
input[type=email] {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  outline: none;
  height: 3rem;
  width: 100%;
  font-size: 16px;
  margin: 0 0 8px 0;
  padding: 0;
  box-shadow: none;
  box-sizing: content-box;
  transition: box-shadow .3s, border .3s;
  cursor: pointer;
}

input:not([type]):focus:not([readonly]),
input[type=text]:focus:not([readonly]),
input[type=email]:focus:not([readonly]) {
  border-bottom: 1px solid var(--primary-color);
  box-shadow: 0 1px 0 0 var(--primary-color);
}

input:not([type]):focus:not([readonly])+label,
input[type=text]:focus:not([readonly])+label,
input[type=email]:focus:not([readonly])+label {
  color: var(--primary-color);
}


input:not([type]).validate+label,
input[type=text].validate+label,
input[type=email].validate+label {
  width: 100%;
  cursor: pointer;
}

input:not([type])+label:after,
input[type=text]+label:after,
input[type=email]+label:after {
  display: block;
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  transition: .2s opacity ease-out, .2s color ease-out;
}

.input-field {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.input-field>label {
  color: #9e9e9e;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  font-size: 1rem;
  cursor: text;
  transition: color .2s ease-out;
  transition: transform .2s ease-out, color .2s ease-out;
  transition: transform .2s ease-out, color .2s ease-out;
  transform-origin: 0% 100%;
  text-align: initial;
  transform: translateY(12px);
}

input[type=text]:focus + label.active,
input[type=text]:not([value=""]) + label.active {
  transform: translateY(-14px) scale(0.8);
  transform-origin: 0 0;
}

input:not([type]).validate+label,
input[type=text].validate+label,
input[type=email].validate+label {
  width: 100%;
}

/* @media screen and (max-width: 768px) {
  .input-field {
    width: 100%;
  }
} */
