.range {
  margin: 1rem;
  /* margin-right: 1rem; */
  width: 30vw;
  border: 1px solid black;
  /* height: 1rem; */
  height: 1.2rem;
  border-radius: 0.5rem;
  display: inline-block;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}

.range::-webkit-slider-thumb {
  /* Override default look */
  -webkit-appearance: none;
  background: var(--primary-color);
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}

.range::-moz-range-thumb,
.range::-webkit-slider-thumb {
  /* Override default look */
  appearance: none;
  width: 2.0rem;
  height: 2.0rem;
  border-radius: 50%;
  border: none;
  background: var(--primary-color);
  cursor: pointer;
}

.container {
  display: flex;
  justify-content: center;
  margin: 1rem auto;
  width: 100%;
  /* align-items: center; */
  /* justify-content: center; */
  position: relative;
}

.container > div {
  display: flex;
  flex-direction: column;
  /* margin: 1rem auto; */
  width: 80%;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 10px;
}

.label {
  height: fit-content;
  width: fit-content;
  font-size: 1.5rem;
  text-align: center;
  align-self: flex-end;
}

/* input + .label,
.label + input {
  margin-left: 1rem;
} */

.centerLabel {
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: bold;
  /* position: absolute; */
  /* left: 50%;
  transform: translateX(-50%);
  top: -10px; */
  /* margin: 0.2rem auto; */
}

@media screen and (max-width: 1280px) {
  .range {
    width: 35vw;
  }
}

@media screen and (max-width: 835px) {
  .range {
    width: 40vw;
  }
}

@media screen and (max-width: 768px) {
  .range {
    width: 45vw;
  }
}

@media screen and (max-width: 601px) {
  .range {
    width: 50vw;
  }
}

@media screen and (max-width: 500px) {
  .range {
    width: 55vw;
  }
}
