.pageInfo > h2 {
  margin-bottom: 10px;
}

.pageInfo > p {
  white-space: pre-wrap;
  text-align: center;
  margin-bottom: 20px;
}

.content > p {
  white-space: pre-wrap;
  margin-top: 40px;
}
