.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../assets/order-box2.jpg);
  background-size: cover;
}

.container > h1 {
  margin-top: 0;
  color: #fff;
}

.buttons {
  display: flex;
  /* align-items: center; */
  /* gap: 30px; */
  align-self: center;
  width: fit-content;
  /* padding: 50px; */
  /* background: linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.5)); */
}

.buttons > a {
  width: auto;
  height: 80px;
  padding: 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons > a:not(:last-child) {
  border-right: 1px solid #fff;
}

@media screen and (max-width: 600px) {
  .buttons {
    flex-direction: column;
  }

  .buttons > a {
    height: 60px;
  }

  .buttons > a:not(:last-child) {
    border-bottom: 1px solid #fff;
    border-right: none;
  }
}

@media screen and (max-width: 1024px) and (max-height: 1367px) {
  .container {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../assets/order-box.jpg) center;
    background-size: cover;
  }
}
