.name {
  /* color: #65ADD4;
  color: #9A5A59; */
  color: var(--primary-color);
  /* font-weight: bold; */
  /* font-size: 20px; */
  margin-bottom: 10px;
}

.malav {
  color: var(--primary-color);
  font-weight: bold;
}

.mission {
  margin: 10px 0 50px 0;
  text-align: center;
  white-space: pre-wrap;
}

.container {
  display: flex;
  flex-direction: column;
  /* align-items: stretch; */
  /* padding-bottom: 2rem; */
}

.container > p {
  padding: 0 5rem;
}

.container > h2 {
  text-align: center;
  padding: 0 50px;
  white-space: pre-wrap;
}

.liuba, .olena {
  display: flex;
  /* justify-content: space-between; */
  gap: 50px;
  align-items: center;
  position: relative;
  /* margin-top: 10rem; */
  /* height: 100vh; */
  width: 100%;
  margin-bottom: 50px;
  padding: 50px;
  background-color: rgba(255, 255, 255, 0.5);
}

.liuba > img,
.olena > img {
  /* position: absolute; */
  /* align-self: center; */
  /* width: 60%; */
  /* flex-basis: 0; */
  /* height: 100vh; */
  width: 50%;
  /* flex-shrink: 5; */
  border: 5px solid rgba(23, 179, 173, 0.5);
  /* border-radius: 30px; */
}

.liuba > div,
.olena > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  text-align: center;
  letter-spacing: 1px;
  line-height: 24px;
  /* flex-grow: 5; */
  /* position: absolute; */
  /* height: 100%; */
  /* padding: 50px; */
  /* margin: auto 50px; */
}

.liuba > div > h3, .olena > div > h3 {
  text-align: center;
}

.liuba > img {
  left: 0;
}

.liuba > div {
  right: 0;
  /* bottom: 0; */
  /* margin-right: 5rem; */
}

.olena > img {
  right: 0;
}

.olena > div {
  left: 0;
  bottom: 0;
  flex: 1;
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .liuba, .olena {
    /* height: 100vh; */
    gap: 0;
    padding: 0;
  }

  .liuba > img {
    border-left: none;
  }

  .olena > img {
    border-right: none;
  }

  .liuba > div, .olena > div {
    padding: 50px;
    /* height: 100%; */
    /* padding: 20px 20px; */
    /* font-size: 16px; */
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
.container > h2 {
    padding: 0 20px;
}

.mission {
  margin-bottom: 30px;
  /* text-align: justify; */
}

.liuba, .olena {
  flex-direction: column;
  /* gap: 3rem; */
  /* height: 100vh; */
  /* margin-bottom: 100px; */
}

  .liuba > img,
  .olena > img {
  width: 100%;
  /* height: auto; */
  /* margin-bottom: 0; */
}

.liuba > div,
.olena > div {
  width: 95%;
  line-height: 22px;
  /* padding: 50px; */
  /* margin: 0; */
}

.olena > img {
  order: 1;
}

.olena > div {
  order: 2;
}

/* .name {
  font-size: 18px;
} */
}

@media screen and (max-width: 600px) {
  .container > h2 {
    padding: 0 20px;
  }

  .container > .mission {
    margin-bottom: 20px;
    padding: 0 30px;
    line-height: 16px;
    /* padding: 0 2rem; */
    /* text-align: justify; */
  }

  .liuba, .olena {
    flex-direction: column;
    gap: 0;
    height: auto;
    padding: 0;
    margin-bottom: 30px;
  }

  /* .olena {
    margin-bottom: 30px;
  } */

  .liuba > img {
    width: 100%;
    border-left: none;
    border-right: none;
  }

  .olena > img {
    order: 1;
    width: 100%;
    border-left: none;
    border-right: none;
  }

  .liuba > div {
    width: 100%;
    padding: 30px;
    margin: 0;
    line-height: 22px;
    /* text-align: center; */
    /* position: absolute; */
    /* bottom: 0; */
    /* background-color: transparent; */
  }

  .olena > div {
    order: 2;
    width: 100%;
    padding: 30px;
    margin: 0;
    line-height: 22px;
    /* background-color: transparent; */
  }
}
