iframe {
  border: none;
  width: 100%;
}

.container {
  display: flex;
  justify-content: space-evenly;
  margin: 0.9rem auto;
  width: 100%;
  padding: 20px;
  /* background-color: rgba(255, 255, 255, 0.5); */
}

.col {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.col + .col {
  padding: 50px 50px 20px;
  background-color: rgba(255, 255, 255, 0.5);
}

.col > h1 {
  color: #000;
  margin-bottom: 1rem;
}

.info {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

.info > p {
  width: fit-content;
  font-size: 1rem;
}

.info > p:first-child {
  font-weight: bold;
}

.socialButtons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.socialButtons > a {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-size: cover;
  filter: saturate(.7);
  transition: .3s ease-out;
}

.socialButtons > a:hover {
  filter: saturate(1.2);
}

.socialButtons > a:active {
  filter: saturate(0);
}

.socialButtons > .facebook {
  background-image: url('../assets/fb-logo.png');
}

.socialButtons > .instagram {
  background-image: url('../assets/ig-logo.png');
}

.socialButtons > .youtube {
  background-image: url('../assets/yt-logo.png');
}

.col > textarea {
  resize: vertical;
  padding: 0.5rem;
  margin-top: 1rem;
  width: 90%;
  /* height: 7rem; */
  min-height: 4rem;
  border: none;
  outline: 1px solid #000;
  transition: outline 300ms;
  font-size: 1.2rem;
  background-color: transparent;
}

.col > textarea:focus {
  border: none;
  outline: 1px solid var(--primary-color);
}

.col > textarea::placeholder {
  color: #9e9e9e;
  opacity: 1.0;
}

.col > button {
  margin: 2rem auto 1rem;
  cursor: pointer;
}

.errorStyle {
  color: #fff;
  width: fit-content;
  margin: 1.0rem auto;
  font-size: larger;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 20px 50px;
  background-color: rgba(255, 0, 0, 0.5);
  /* background-color: rgba(0, 255, 0, 0.5); */

}

.messageStyle {
  color: #fff;
  width: fit-content;
  margin: 1.0rem auto;
  font-size: larger;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 20px 50px;
  background-color: var(--primary-color);
}

.pending {
  color: var(--primary-color);
  width: fit-content;
  margin: 1.0rem auto;
}

@media screen and (max-width: 600px) {
  .container {
    padding: 0;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    display: block;
    margin-bottom: 0;
    padding: 0;
    width: 100%;
  }

  .container > .col {
    width: 100%;
  }

  .container > .col + .col {
    margin: 0;
    /* padding: 50px 50px 20px; */
    /* margin-top: 30px; */
  }

  iframe {
    width: 100%;
  }
}
