.price {
  color: var(--primary-color);
}

.buttons {
  display: flex;
  gap: 5px;
  margin: 5px auto;
  width: fit-content;
}
