.questions {
  margin: 10px auto;
  /* padding: 0 30px; */
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 1.5rem; */
}

.message > p {
  margin-top: 15px;
  font-size: 24px;
}

.message > * {
  width: fit-content;
  margin: 0 auto;
  text-align: center;
}

.validationMessage {
  margin-top: 5px;
  color: #f00;
}

.agree {
  color: #000;
  font-weight: bold;
  text-decoration: underline;
}

@media screen and (max-width: 1280px) {
  .questions {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .questions {
    width: 55%;
  }

  .message > p {
    font-size: 20px;
  }
}

@media screen and (max-width: 835px) {
  .questions {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .questions {
    width: 65%;
  }
}

@media screen and (max-width: 601px) {
  .questions {
    width: 75%;
  }

  .message > p {
    font-size: 18px;
  }
}

@media screen and (max-width: 500px) {
  .questions {
    width: 90%;
  }
}
