.selectBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  margin: 0 auto;
}

.selectBox > p {
  font-size: 1.5rem;
  text-align: center;
}

p.extraError {
  margin-top: 15px;
  color: #f21;
  font-weight: bold;
  font-size: 1.2rem;
}

@media screen and (max-width: 1280px) {
  .selectBox {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .selectBox {
    width: 55%;
  }
}

@media screen and (max-width: 835px) {
  .selectBox {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .selectBox {
    width: 65%;
  }
}

@media screen and (max-width: 601px) {
  .selectBox {
    width: 75%;
  }
}

@media screen and (max-width: 500px) {
  .selectBox {
    width: 90%;
  }
}
