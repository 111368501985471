.container {
  width: 50%;
  margin: 0 auto;
  padding: 10px 30px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  border: 5px solid rgba(23, 179, 173, 0.5);
}

.container > p {
  margin: 10px auto;
  width: fit-content;
  font-weight: 700;
}

@media screen and (max-width: 1280px) {
  .container {
    width: 60%;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 90%;
  }
}
