.btn {
  border: none;
  display: block;
  height: 3rem;
  line-height: 3rem;
  font-size: 1.5rem;
  text-align: center;
  color: #fff;
  background-color: var(--primary-color);
  text-decoration: none;
  text-transform: uppercase;
  transition: 700ms;
  padding: 0 1.5rem;
}

.btn:hover {
  color: var(--primary-color);
  background-color: #fff;
  outline: 1px solid var(--primary-color);
  cursor: pointer;
}
