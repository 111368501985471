label {
  font-size: .8rem;
  color: #000;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

[type="checkbox"]+span {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[type="checkbox"]+span:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid #5a5a5a;
  border-radius: 1px;
  margin-top: 3px;
  -webkit-transition: .2s;
  transition: .2s;
}

[type="checkbox"]+span:after {
  border: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="checkbox"]:not(:checked):disabled+span:before {
  border: none;
  background-color: rgba(0, 0, 0, 0.42);
}

[type="checkbox"]:checked+span:before {
  top: -4px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  /* color of checkbox */
  border-right: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type="checkbox"]:checked:disabled+span:before {
  border-right: 2px solid rgba(0, 0, 0, 0.42);
  border-bottom: 2px solid rgba(0, 0, 0, 0.42);
}

[type="checkbox"]:indeterminate+span:before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  /* color of checkbox */
  border-right: 2px solid var(--primary-color);
  border-bottom: none;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
