.link {
  margin: 0 auto;
  width: fit-content;
  cursor: pointer;
}

.center {
  width: fit-content;
  margin: 5px auto;
}

.logout {
  margin: 10px auto;
}

.done {
  text-decoration: line-through;
}
