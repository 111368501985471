.qa, .noQuestions {
  text-align: center;
}

.qa + .qa {
  margin-top: 0.5rem;
}

.qaQuestion {
  color: #0000ff;
}

.qaAnswer {
  color: #dddd00;
}

.category {
  margin: 0.7rem auto;
  width: fit-content;
  color: var(--primary-color);
}

.answers {
  margin: 16px auto;
}

.center {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
